@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-logo {
  animation: App-logo;
}


.App-header {
  background-color: #002959;
  height: 80px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


#sign-out {
  background-color: transparent;
  position:absolute;
  right: 0px;
  border: 0px solid #000; /* You can adjust the border properties as needed */
  color: #000; /* You can adjust the text color */
  padding: 50px 25px; /* You can adjust the padding as needed */
  font-size: 24px; /* You can adjust the font size */
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  color: #fff;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.left-align-container {
  text-align: left;
  padding-left: 100px;
  font-family:'Open Sans', 'Helvetica Neue', sans-serif
}

.horizontal-div div {
  display: inline-block;
}

.spacer {
  flex-grow: 1; /* This will make the spacer div grow and fill the available space */
}

#change-holder {
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

#transactions {
  height: auto;
  overflow-y:auto;
  height: 300px;
}

#overall-box{
  margin: 0;
  width: 100vw;
}

#graph-table-holder {
  position: relative;
  left: 0;
  margin: 0;
}
/*
#right-col{
  background-color: darkgoldenrod;
}*/

#graph-holder {
  position: relative;
}

#daily-change {
  background-color: rgba(52,210,162 ,0.58);/*#34D2A2;*/ /* Background color of the rounded rectangle */
  border: 6px solid #34D2A2; /* You can adjust the border properties as needed */
  padding: 7px; /* Padding around the text */
  padding-left: 30px;
  padding-right: 30px;
  align-self: center;
  border-radius: 50px; /* Border radius to create rounded corners */
  width: fit-content; /* Adjust width to fit content */
  height: fit-content; /* Adjust width to fit content */
  font-size: 18; /* You can adjust the font size */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#mobile-stats {
  align-self: center;
  width: 80vw;
}

#stats-box {
  margin-top: 0;
  position:relative;
  width: auto;
  height: min-content;
}

#last-updated {
  align-self: center;
  padding: 7px; /* Padding around the text */
  padding-left: 30px;
  padding-right: 10px;
  bottom: 0;
  right: 0;
  width: fit-content; /* Adjust width to fit content */
  height: fit-content; /* Adjust width to fit content */
  font-size: 18; /* You can adjust the font size */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#right-col {
  align-items: center;
}

.rounded-box {
  margin-top: 80px;
  background-color: rgba(255,255,255 ,1);/*#34D2A2;*/ /* Background color of the rounded rectangle */
  padding: 7px; /* Padding around the text */
  align-items: start;
  padding-top: 20px;
  padding-left:30px;
  padding-right: 20px;
  border-radius: 20px; /* Border radius to create rounded corners */
  width: 80%; /* Adjust width to fit content */
  height: fit-content; /* Adjust width to fit content */
  font-size: 18; /* You can adjust the font size */
  font-family:Arial, Helvetica, sans-serif;
  box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.3);
}



#overall-row {
    text-align: left;
    line-height: 1.3;
    padding-left: 0px; /* Padding around the text */
    padding-top: 30px; /* Padding around the text */
    padding-bottom: 0px;
    height: 88vh;
}

.alert {
  margin: 0 auto; /* Centers the element horizontally */
  width: fit-content; /* Ensures width adapts to content */
  /* Other styles for the alert */
}

